import React, { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { FormElement } from "..";
import { colors } from "../../../config/theme/colors";

const wrapperProps = {
    as: styled.div`
        :after {
            content: "";
            position: absolute;
            display: inline-flex;
            border-style: solid;
            border-radius: 0.15em;
            border-color: #9ca3af;
            border-width: 0.18em 0.18em 0 0;
            width: 0.72em;
            height: 0.72em;
            top: 50%;
            right: 1.2em;
            transform: rotate(135deg);
            pointer-events: none;
        }
        > select {
            appearance: none;
            background: transparent;
            padding-right: 2.2rem;
            color: ${colors.gray[900]};
            text-overflow: ellipsis;

            &[data-is-default="true"] {
                color: ${colors.gray[400]};
            }
        }
    `,
};

export const Select = ({ defaultValue, ...props }) => {
    const ref = useRef();
    useEffect(() => {
        const elem = ref.current;
        if (!elem) return;
        elem.value = defaultValue;
        if (elem.selectedIndex < 0) elem.selectedIndex = 0;
    }, [defaultValue]);

    return (
        <FormElement
            as="select"
            ref={ref}
            minWidth={["50px", "75px", "100px"]}
            wrapperProps={wrapperProps}
            pr="2rem"
            defaultValue={defaultValue}
            {...props}
        />
    );
};
