import shouldForwardProp from "@styled-system/should-forward-prop";
import React from "react";
import styled from "styled-components";
import { Box, composeAll, sizes, Text } from "../../assets/styles/common";
import { theme } from "../../config/theme";
import { colors } from "../../config/theme/colors";

export const FormControl = styled(Box).withConfig({
    shouldForwardProp,
})`
    position: relative;
    width: 100%;

    input,
    select,
    textarea {
        ${({ error }) => (error ? `border-color: ${theme.colors.red[500]};` : "")};
    }
`;

// TODO: improve label size scaling by input size
export const Component = styled.input.withConfig({
    shouldForwardProp,
})`
    width: 100%;
    border: 1px solid ${theme.colors.gray[300]};
    padding-top: 0.9em;
    line-height: 1.5;
    background-color: transparent;
    color: ${colors.gray[900]};
    ${composeAll}
    ::placeholder {
        color: #9ca3af;
    }
`;

export const FormElement = React.forwardRef(({ label, size, wrapperProps = {}, labelProps = {}, ...rest }, ref) => {
    const sz = sizes[size] || sizes.md;

    return (
        <FormControl {...wrapperProps}>
            <Text position="absolute" color="gray.500" fontSize=".85rem" ml={sz.px} mt=".45rem" {...labelProps}>
                {label}
            </Text>
            <Component ref={ref} key={rest.name || label} {...sz} {...rest} />
        </FormControl>
    );
});
