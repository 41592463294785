import shouldForwardProp from "@styled-system/should-forward-prop";
import React from "react";
import styled from "styled-components";
import { Box, Text } from "../../assets/styles/common";
import { theme } from "../../config/theme";

export const Controller = styled(Box).withConfig({
    shouldForwardProp,
})`
    input,
    select,
    textarea {
        ${({ error }) => (error ? `border-color: ${theme.colors.red[500]};` : "")};
    }
`;

const FormController = ({ error, errorProps, children, ...rest }) => {
    return (
        <Controller error={!!error} {...rest}>
            {children}
            {error && typeof error === "string" ? (
                <Text
                    as="div"
                    position="relative"
                    height={0}
                    color="red.500"
                    fontSize="xs"
                    fontWeight="500"
                    top={["2px", "2px", "2px", "3px"]}
                    {...errorProps}
                >
                    {error}
                </Text>
            ) : null}
        </Controller>
    );
};
export default FormController;
