import React, { useCallback, useEffect, useMemo, useRef } from "react";
import NumberFormat from "react-number-format";
import { FormElement } from "..";

export const Input = (props) => <FormElement {...props} />;

export const FormattedNumberInput = ({ options, ...rest }) => (
    <FormElement as={(props) => <NumberFormat {...options} {...props} />} {...rest} />
);

export const NumberInput = ({ options, name, onValueChange, defaultValue, ...rest }) => {
    // a rerender is causing the input to reset to the default value (alternative is to have a controlled input)
    const defaultValueRef = useRef(defaultValue);
    const inputRef = useRef();
    const callbackRef = useRef(onValueChange);

    // if the callback is not memoized then it can change on every render and cause rerenders of the input
    useEffect(() => {
        callbackRef.current = onValueChange;
    }, [onValueChange]);

    const onInternalChange = useCallback((args) => {
        if (callbackRef.current) callbackRef.current(args);
        defaultValueRef.current = args.value;
        if (inputRef.current) inputRef.current.value = args.value;
    }, []);

    const input = useMemo(
        () => (props) => <NumberFormat {...options} onValueChange={onInternalChange} {...props} />,
        [onInternalChange, options],
    );

    return (
        <>
            <input type="hidden" name={name} defaultValue={defaultValueRef.current} ref={inputRef} />
            <FormElement key={name} as={input} defaultValue={defaultValueRef.current} {...rest} />
        </>
    );
};
